/* eslint-disable no-console */
/* eslint indent: [1, 2, {"SwitchCase": 1}]*/

'use strict';

const App = {};

App.ready = (callback) => {
  if (document.readyState != 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
};

App.ready(() => {
  App.init();
});

App.init = function () {
  // console.log('App is ready!');
  this.initMobileNavigation();
  this.initAgencesModal();
  this.initOffresEmploi();
};

App.initMobileNavigation = function () {
  const $button = document.getElementById('js-mobile-menu-toggle');
  const $menu = document.getElementById('js-mobile-menu');

  if (!$button || !$menu) return;

  const $header = document.getElementById('js-header');

  $button.addEventListener('click', (e) => {
    e.preventDefault();
    $menu.classList.toggle('hidden');
    $button.classList.toggle('active');
    $header.classList.toggle('text-white');
    $header.classList.toggle('text-blue');
    $header.classList.toggle('border-white');
    $header.classList.toggle('border-blue');
    document.body.style.overflowY =
      document.body.style.overflowY === 'hidden' ? 'visible' : 'hidden';
  });
};

App.initAgencesModal = function () {
  const $modal = document.getElementById('js-agences-modal');

  if (!$modal) return;

  const $trigger = document.querySelectorAll('.js-agences-modal-trigger');
  const $modalTitle = document.getElementById('js-agences-modal-title');
  const $modalContent = document.getElementById('js-agences-modal-content');
  const $modalClose = document.getElementById('js-agences-modal-close');
  const $modalBg = document.getElementById('js-agences-modal-bg');

  $trigger.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();

      const dataType = e.target.getAttribute('data-type');
      const dataLink = e.target.getAttribute('data-link');
      const dataText = e.target.getAttribute('data-text');
      if (dataType == 'phone') {
        $modalTitle.innerHTML = "Appeler l'agence";
      } else if (dataType == 'mail') {
        $modalTitle.innerHTML = 'Envoyer un email';
      } else if (dataType == 'gps') {
        $modalTitle.innerHTML = 'Coordonnées GPS';
      }

      $modalContent.innerHTML = `<a href="${dataLink}">${dataText}</a>`;
      $modal.classList.remove('hidden');
    });
  });

  $modalBg.addEventListener('click', (e) => {
    // test if tartget has id == js-agences-modal-bg
    if (e.target.id == 'js-agences-modal-bg') {
      $modal.classList.add('hidden');
    }
  });

  $modalClose.addEventListener('click', (e) => {
    e.preventDefault();
    $modal.classList.add('hidden');
  });
};

App.initOffresEmploi = function () {
  const $offres = document.querySelector('.js-offres-emploi');
  if (!$offres) return;

  const $toggle = document.querySelectorAll('.js-toggle');
  $toggle.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      const key = element.getAttribute('data-target');
      const $target = document.querySelector('[data-content="' + key + '"]');
      $target.classList.toggle('hidden');
      const $close = $target.querySelector('.js-close');
      $close.addEventListener('click', (e) => {
        e.preventDefault();
        $target.classList.add('hidden');
      });
    });
  });
};
